
import { ValueOf } from 'type-fest';
import Vue, { PropType } from 'vue';

import { ApiEditoBlockMain, EOrientation } from '@/domain/edito/types';
import { Product } from '@/domain/product/types';
import { _ga4ProductAdapter } from '@/infrastructure/externalServices/ga4/adapters/ga4ProductAdapter';
import { generateItemListIdentifier } from '@/infrastructure/externalServices/ga4/generateItemListIdentifier';
import { GA4ECommerceItemList } from '@/infrastructure/externalServices/ga4/types';
import { EGtmEcommerceListName, EGtmEventEcommerce } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'EditoBlockMain',
  props: {
    content: {
      type: Object as PropType<ApiEditoBlockMain>,
      required: true,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
    trackingId: {
      type: String as PropType<ValueOf<typeof EGtmEcommerceListName>>,
      required: true,
    },
  },
  computed: {
    isReversed(): boolean {
      return this.content.orientation === EOrientation.Left;
    },
    itemListIdentifier(): GA4ECommerceItemList {
      return generateItemListIdentifier(this.trackingId);
    },
    products(): Partial<Product>[] {
      const { first, fourth, second, third } = this.content;

      return [first, second, third, fourth];
    },
  },
  mounted() {
    this.handleTracking();
  },
  methods: {
    handleClickProduct(product: Partial<Product>): void {
      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.SelectItem,
        ecommerce: {
          ...this.itemListIdentifier,
          items: [{
            ...this.itemListIdentifier,
            ..._ga4ProductAdapter(product),
          }],
        },
      });
    },
    handleTracking(): void {
      if (this.products?.length) {
        const fmtProducts = this.products.map((product, index) => ({
          ..._ga4ProductAdapter(product, index),
          ...this.itemListIdentifier,
        }));

        this.$gtm.push({ ecommerce: null });
        this.$gtm.push({
          event: EGtmEventEcommerce.ViewItemList,
          ecommerce: {
            ...this.itemListIdentifier,
            items: fmtProducts,
          },
        });
      }
    },
  },
});
