
import { ValueOf } from 'type-fest';
import Vue, { PropType } from 'vue';

import { ApiEditoBlockSelection, EBackgroundType } from '@/domain/edito/types';
import { Product } from '@/domain/product/types';
import { BaseInteractiveElement } from '@/infrastructure/core/generic/BaseInteractiveElement.interface';
import { _ga4ProductAdapter } from '@/infrastructure/externalServices/ga4/adapters/ga4ProductAdapter';
import { generateItemListIdentifier } from '@/infrastructure/externalServices/ga4/generateItemListIdentifier';
import { GA4ECommerceItemList } from '@/infrastructure/externalServices/ga4/types';
import { EGtmEcommerceListName, EGtmEventEcommerce } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'EditoBlockSelection',
  props: {
    content: {
      type: Object as PropType<ApiEditoBlockSelection>,
      required: true,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
    trackingId: {
      type: String as PropType<ValueOf<typeof EGtmEcommerceListName>>,
      required: true,
    },
  },
  computed: {
    blockCta(): BaseInteractiveElement {
      const { backgroundColor, link: url, title } = this.content?.cta || {}; // WARNING: 'fontColor' is not supported by the BaseTile.

      return {
        backgroundColor,
        backgroundType: EBackgroundType.SolidColor,
        title,
        url,
      };
    },
    itemListIdentifier(): GA4ECommerceItemList {
      return generateItemListIdentifier(this.trackingId);
    },
    products(): Partial<Product>[] {
      const { fifth, first, fourth, second, seventh, sixth, third } = this.content;

      return [first, second, third, fourth, fifth, sixth, seventh];
    },
  },
  mounted() {
    this.handleTracking();
  },
  methods: {
    handleClickProduct(product: Partial<Product>): void {
      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.SelectItem,
        ecommerce: {
          ...this.itemListIdentifier,
          items: [{
            ...this.itemListIdentifier,
            ..._ga4ProductAdapter(product),
          }],
        },
      });
    },
    handleTracking(): void {
      const fmtProducts = this.products.map((product, index) => ({
        ..._ga4ProductAdapter(product, index),
        ...this.itemListIdentifier,
      }));

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.ViewItemList,
        ecommerce: {
          ...this.itemListIdentifier,
          items: fmtProducts,
        },
      });
    },
  },
});
